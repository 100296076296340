import PropTypes from 'prop-types'

import { useUser, useUserCheck } from '~/hooks'

function ProgressBar({ index }) {
  let { userId } = useUserCheck()
  let { user } = useUser(userId)

  const handleGoToIndex = index => {
    return true
  }

  return (
    <div className="flex pt-4 space-x-1">
      {user?.candidates?.map((item, index2) => (
        <div
          onClick={() => handleGoToIndex(index2)}
          className={
            'flex-1 h-1 rounded-md ' + (index >= index2 ? 'bg-orange-400' : item ? 'bg-gray-100' : 'bg-gray-100')
          }
          key={index2}
        ></div>
      ))}
    </div>
  )
}

ProgressBar.propTypes = {
  index: PropTypes.number.isRequired,
}

/**
 * Exports
 */

export default ProgressBar

import PropTypes from 'prop-types'

import * as auth from '~/lib/auth'

const ISPHASEONE = process.env.REACT_APP_ISPHASEONE === 'TRUE'

/**
 * Helpers
 */

/**
 * MobileLayout
 */

function ThanksScreen({ forceUpdateHandler }) {
  return (
    <div className="px-6 mb-10">
      <div className="text-white100 text-4axl leading-relaxed font-primary text-center mt-14 py-11 bg-gray-800 rounded-t-lg">
        ¡Gracias por participar!
      </div>
      <img src="/images/thanks.png" />
      <div className=" py-8 text-white100 text-base font-secondary text-center">
        {ISPHASEONE
          ? 'Quedate atentx a los próximos dias que se votan los más elegidos.'
          : 'Quedate atentx que en los próximos días tendremos los resultados.'}
      </div>
      <div className="flex pt-4 space-x-1 w-5/12 mx-auto my-0">
        <div className="flex-1 h-1 rounded-md">
          <a href="https://www.instagram.com/filonewsok/" target="_blank" rel="noreferrer">
            <img src="/images/icon-instagram.svg" alt="" />
          </a>
        </div>
        <div className="flex-1 h-1 rounded-md">
          <a href="https://twitter.com/filonewsok" target="_blank" rel="noreferrer">
            <img src="/images/icon-twitter.svg" alt="" />
          </a>
        </div>
        <div className="flex-1 h-1 rounded-md">
          <a
            href="https://www.youtube.com/channel/UC9vs8KujZ2kJ2hbg01fNRVw?sub_confirmation=1"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/icon-youtube.svg" alt="" />
          </a>
        </div>
      </div>
      <div className="mt-16 text-red-300 text-center">
        <a className="underline" href="https://filo.news" target="_blank" rel="noreferrer">
          Visitar Filo.News
        </a>
      </div>
      {process.env.REACT_APP_ENV === 'development' && (
        <div className="mt-2 text-red-300 text-center">
          <a
            className="underline"
            onClick={() => {
              auth.removeUserId()
              window.location.reload()
            }}
            target="_blank"
            rel="noreferrer"
          >
            Volver a jugar
          </a>
        </div>
      )}
    </div>
  )
}

ThanksScreen.propTypes = {
  forceUpdateHandler: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default ThanksScreen

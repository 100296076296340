import { useCallback, useEffect, useState } from 'react'
import { animateScroll as scroll } from 'react-scroll'

import { sendAnalyticsEvent } from '~/helpers'

/**
 * Constants
 */

export const ScrollIds = Object.freeze({
  PLAY: 'play',
  MAP: 'map',
  PROGRESS: 'progress',
})

/**
 * Header
 */

const Header = () => {
  const [scrolled, setScrolled] = useState(false)

  const handleScroll = e => {
    const scrollTop = e.target.scrollingElement.scrollTop

    if (scrollTop > 115) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  const handleLogoClick = useCallback(() => {
    scroll.scrollToTop()
    sendAnalyticsEvent('Navigation', 'Logo')
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      <header className="w-full p-6">
        <div role="button" className="mt-2" onClick={handleLogoClick}>
          <img className="" src="/images/logo-filotop.svg" />
        </div>
      </header>
    </>
  )
}

/**
 * Exports
 */

export default Header

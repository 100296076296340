import { useCallback, useState } from 'react'
import ReactGA from 'react-ga'
import { isNil } from 'lodash'
import useSWR from 'swr'

import * as api from '~/api'
import apiClient from '~/lib/api-client'
import * as auth from '~/lib/auth'

/**
 * Helpers
 */

const fetcher = url => apiClient.get(url)

/**
 * Hooks
 */

export const useUserCheck = () => {
  const [userId, setUserId] = useState(auth.getUserId())

  const updateUser = useCallback(async (user, id) => {
    await api.updateUser(user, id)
  }, [])

  const updateVoteUser = useCallback(async (user, id, index, vote) => {
    await api.updateVoteUser(user, id, index, vote)
  }, [])

  const updateInfoUser = useCallback(async (user, id) => {
    await api.updateInfoUser(user, id)
  }, [])

  const createUser = useCallback(async () => {
    const newUser = await api.createUser()
    auth.setUserId(newUser.token)
    setUserId(newUser.token)

    ReactGA.set({ dimension1: newUser.id })

    return newUser.token
  }, [])

  return { updateUser, createUser, updateVoteUser, updateInfoUser, userId }
}

export const useUser = userId => {
  const { data, error } = useSWR(!isNil(userId) ? `api/users/me?id=${userId}` : null, fetcher)

  return {
    user: data?.data,
    isLoading: !error && !data,
    error: error,
  }
}

import ReactGA from 'react-ga'
import { isNil, reduce } from 'lodash'

export const getProgressCount = reindeers =>
  reduce(
    reindeers,
    (sum, n) => {
      const shouldFilter = isNil(n.track_date)
      const next = shouldFilter ? 0 : 1

      return sum + next
    },
    0
  )

export const sendAnalyticsEvent = (category, label) =>
  ReactGA.event({
    category,
    action: 'Click',
    label,
  })

export const isDreidelDashModeEnabled = process.env.REACT_APP_DREIDEL_DASH_MODE === 'TRUE'

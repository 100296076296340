import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { useUserCheck } from '~/hooks'
import ButtonLoader from '../global/ButtonLoader'
/**
 * Constants
 */

export const ScrollIds = Object.freeze({
  PLAY: 'play',
  MAP: 'map',
  PROGRESS: 'progress',
})

/**
 * Header
 */

const ButtonWelcome = ({ forceUpdateHandler }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { createUser } = useUserCheck()
  const welcomeMessage = process.env.REACT_APP_ISPHASEONE === 'TRUE' ? '¡Comenzar!' : '¡Quiero votar!'
  const handler = process.env.REACT_APP_ISPHASEONE === 'TRUE' ? 3 : 2

  const handleButtonClick = useCallback(async () => {
    setIsSubmitting(true)
    await createUser()
    forceUpdateHandler(handler)
  }, [createUser, forceUpdateHandler, handler])

  return (
    <div
      className="bg-gradient-to-r from-red-500 to-purple-100 text-center text-white text-base rounded-full my-8 w-44 px-8 py-3 mx-auto"
      onClick={handleButtonClick}
    >
      {!isSubmitting ? welcomeMessage : <ButtonLoader />}
    </div>
  )
}

ButtonWelcome.propTypes = {
  forceUpdateHandler: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default ButtonWelcome

import { useEffect, useState } from 'react'

import Header from '~/components/Header'
import FormScreen from '~/components/screens/FormScreen'
import SurveyScreen from '~/components/screens/SurveyScreenPH2'
import ThanksScreen from '~/components/screens/ThanksScreen.js'
import WelcomeScreen from '~/components/screens/WelcomeScreen'
import { useUser, useUserCheck } from '~/hooks'

/**
 * Helpers
 */

/**
 * MobileLayout
 */

function MobileLayout() {
  const [view, setView] = useState(1)
  let { userId } = useUserCheck()
  let { user } = useUser(userId)

  useEffect(() => {
    var empties = user?.votes?.length - user?.votes?.filter(word => parseInt(word, 10) !== 0).length - 1
    console.log('EMPTY', userId, empties, user?.votes)
    if (userId && !empties) {
      setView(4)
    } else if (userId && empties === 9) {
      setView(1)
    }
  }, [user?.votes, userId])

  // const hasData = !isEmpty(user?.answers) || !isEmpty(user?.answers)
  const forceUpdateHandler = view => {
    // console.log('forceUpdateHandler', view)
    setView(view)
  }

  const main =
    view === 1 ? (
      <WelcomeScreen forceUpdateHandler={forceUpdateHandler} />
    ) : view === 2 ? (
      <SurveyScreen forceUpdateHandler={forceUpdateHandler} />
    ) : view === 3 ? (
      <FormScreen forceUpdateHandler={forceUpdateHandler} />
    ) : (
      <ThanksScreen forceUpdateHandler={forceUpdateHandler} />
    )

  return (
    <div className="max-w-sm mx-auto qa">
      <Header />
      {main}
      <style jsx>{`
        .qa {
          /* background-image: url('/images/3.png');
          background-size: 100%;
          background-position: top left;
          background-repeat: no-repeat; */
        }
      `}</style>
    </div>
  )
}

/**
 * Exports
 */

export default MobileLayout

import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { useUser, useUserCheck } from '~/hooks'
import * as auth from '~/lib/auth'
import ButtonLoader from '../global/ButtonLoader'

/**
 * Helpers
 */

/**
 * MobileLayout
 */

function FormScreen({ forceUpdateHandler }) {
  const refContainer = useRef()
  const refContainer2 = useRef()
  const [isSubmitting, setIsSubmitting] = useState(false)
  let { userId, updateInfoUser } = useUserCheck()
  let { user } = useUser(userId)

  const handleVotar = () => {
    user.nombre = refContainer.current.value
    user.email = refContainer2.current.value
    updateInfoUser(user, userId)
    setIsSubmitting(true)
    setTimeout(() => {
      forceUpdateHandler(4)
    }, 700)
  }

  return (
    <div className="px-6 mb-10">
      <div className="text-white100 text-4axl leading-relaxed font-primary text-center mt-14 py-11 bg-red-300 rounded-t-lg px-10">
        Si llegaste hasta acá...
      </div>
      <img className="w-full -mt-2" src="/images/formbottom.png" />
      <div className=" py-8 text-white100 text-base font-secondary text-center px-4">
        ¡Vos también podés estar entre lxs grandes! Completá tus datos y participá por uno de los premios que{' '}
        <strong>Filo</strong> y <strong>0800 Don Rouch</strong> tienen para vos.
      </div>

      <div>
        <p className="px-5 pt-8 text-xs pb-1 text-white100 text-secondary">Nombre</p>
      </div>
      <div className="relative">
        <input
          className="p-4 w-full rounded-full focus:outline-none outline-none"
          ref={refContainer}
          placeholder="Nombre"
          type="text"
        />
      </div>

      <div>
        <p className="px-5 pt-8 text-xs pb-1 text-white100 text-secondary">Email</p>
      </div>
      <div className="relative">
        <input
          className="p-4 w-full rounded-full focus:outline-none outline-none"
          ref={refContainer2}
          placeholder="Email"
          type="text"
        />
      </div>
      <div
        className="bg-gradient-to-r from-red-500 to-purple-100 text-center text-white text-base rounded-full my-8 w-44 px-8 py-3 mx-auto"
        onClick={handleVotar}
      >
        {!isSubmitting ? 'Enviar' : <ButtonLoader />}
      </div>

      <div className="mt-16 text-red-300 text-center">
        <a
          className="underline"
          rel="noreferrer"
          onClick={() => {
            forceUpdateHandler(4)
          }}
        >
          No, gracias
        </a>
      </div>
    </div>
  )
}

FormScreen.propTypes = {
  forceUpdateHandler: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default FormScreen

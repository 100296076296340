import Bugsnag from '@bugsnag/js'

import apiClient from '~/lib/api-client'

const createUser = async () => {
  try {
    const { data } = await apiClient.post('/api/users/create')

    return data
  } catch (error) {
    Bugsnag.notify(error)
  }
}

const updateUser = async (user, id) => {
  try {
    const { data } = await apiClient.post('/api/users/update', { candidates: user?.candidates, id })

    return data
  } catch (error) {
    Bugsnag.notify(error)
  }
}

const updateVoteUser = async (user, id, index, vote) => {
  try {
    const { data } = await apiClient.post('/api/users/update', { votes: user?.votes, id, index, vote })

    return data
  } catch (error) {
    Bugsnag.notify(error)
  }
}

const updateInfoUser = async (user, id) => {
  try {
    const { data } = await apiClient.post('/api/users/saveInfo', { nombre: user?.nombre, email: user?.email, id })

    return data
  } catch (error) {
    Bugsnag.notify(error)
  }
}

export { createUser, updateUser, updateVoteUser, updateInfoUser }

import PropTypes from 'prop-types'

import ButtonWelcome from '~/components/welcome/ButtonWelcome'
import OnBoarding from '~/components/welcome/OnBoarding'

const ISPHASEONE = process.env.REACT_APP_ISPHASEONE === 'TRUE'

/**
 * Helpers
 */

/**
 * MobileLayout
 */

function WelcomeScreen({ forceUpdateHandler }) {
  return (
    <div className="px-6 mb-10">
      {ISPHASEONE ? (
        <>
          <img className="pt-3" src="/images/welcome-hero-mobile.png" />
          <p className="font-primary text-5xl font-bold text-white my-8 leading-13">
            Elegí lo mejor de <span className="text-red-300">la escena digital</span>
          </p>
          <OnBoarding></OnBoarding>
        </>
      ) : (
        <>
          <img className="pt-3" src="/images/welcome-hero-mobile2.png" />{' '}
          <p className="font-primary text-5xl font-bold text-white my-8 leading-13">
            Ya podés votar a lo mejor de <span className="text-red-300">la escena digital</span>
          </p>
          <p className="font-secondary text-xs text-white mt-8">
            Nos ayudaste a definir a lxs mejorxs de cada categoría, ¡ahora con tu voto llevalos hasta la cima!
          </p>
          <p className="font-secondary text-xs text-white mt-2">
            Los ganadores se llevarán un premio de <strong>Filo</strong> y <strong>0800 Don Rouch</strong>.
          </p>
        </>
      )}
      <ButtonWelcome forceUpdateHandler={forceUpdateHandler} />
    </div>
  )
}

WelcomeScreen.propTypes = {
  forceUpdateHandler: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default WelcomeScreen

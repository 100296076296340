import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { useUser, useUserCheck } from '~/hooks'
import ButtonLoader from '../global/ButtonLoader'

/**
 * MobileLayout
 */

function SurveyBox({ index, data, slug, title, goToNext }) {
  let { userId } = useUserCheck()
  let { user } = useUser(userId)
  const [btnEnable, setBtnEnable] = useState(false)
  const [vote, setVote] = useState(-1)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleVotar = () => {
    if (vote < 0) {
      return
    }
    setIsSubmitting(true)
    setTimeout(() => {
      goToNext(index, vote)
      setVote(-1)
      setIsSubmitting(false)
    }, 700)
  }

  // console.log('refContainer.current', refContainer?.current?.value)

  return (
    <div className="pt-8">
      <div>
        {data.map((host, index2) => (
          <div
            key={index2}
            onClick={e => {
              setVote(index2)
            }}
          >
            <div className="option relative overflow-hidden mb-2 rounded-full cursor-pointer bg-heading text-white text-sm bg-gray-800">
              {vote === index2 && (
                <div
                  className="progress absolute h-full rounded-full font-secondary text-base bg-red-300"
                  style={{ width: '100%' }}
                />
              )}
              {/* <div
                className="progress absolute h-full rounded-full font-secondary text-base opacity-25 bg-white100"
                style={{ width: '50%' }}
              /> */}
              <div className="flex flex-row h-full items-center justify-between py-1 px-1">
                <div className="flex flex-row z-10 items-center justify-between">
                  <img className="mr-2 h-14 w-14 rounded-full" src={host.image} alt={host.name} />
                  <span className="font-semibold h-full pr-10 pl-2 font-secondary text-base">{host.name}</span>
                </div>
                {/* <span className="z-10 font-bold pr-6 font-secondary text-base">99%</span> */}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="relative">
        <div
          className={`${
            vote >= 0 ? 'bg-gradient-to-r from-red-500 to-purple-100 ' : 'bg-gray-500'
          } text-center text-white text-base rounded-full my-8 w-44 px-8 py-3 mx-auto`}
          onClick={handleVotar}
        >
          {!isSubmitting ? 'Votar' : <ButtonLoader />}
        </div>
        <div className="absolute right-0 top-0 w-15 h-15">
          <svg
            className="btn_submit"
            width="22"
            height="22"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity={btnEnable ? `1.0` : `0.5`}
              d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z"
              fill={btnEnable ? `#FD0450` : `#151515`}
            />
          </svg>
        </div>
      </div>
      <style jsx>{`
        .btn_submit {
          margin-top: 18px;
          margin-left: 20px;
        }
      `}</style>
    </div>
  )
}

SurveyBox.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  goToNext: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default SurveyBox

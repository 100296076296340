import React from 'react'
import Loader from 'react-loader-spinner'
import PropTypes from 'prop-types'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

/**
 * ButtonLoader
 */

const ButtonLoader = ({ color, size }) => {
  return (
    <div>
      <Loader className="mx-auto w-6" type="TailSpin" color={color} height={size} width={size} />
    </div>
  )
}

/**
 * PropTypes
 */

ButtonLoader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
}

ButtonLoader.defaultProps = {
  color: '#fff',
  size: 20,
}

/**
 * Exports
 */

export default ButtonLoader

import { useCallback, useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import PropTypes from 'prop-types'

import ProgressBar from '~/components/survey/ProgressBar'
import SurveyBox from '~/components/survey/SurveyBoxPH2'
import TitleBox from '~/components/survey/TitleBox'
import { useUser, useUserCheck } from '~/hooks'
import data from '~/lib/data.json'

/**
 * MobileLayout
 */
function SurveyScreen({ forceUpdateHandler }) {
  const slug = [
    'youtuber',
    'instagrammer',
    'tiktoker',
    'twitstar',
    'streamer',
    'gamer',
    'freestyler',
    'musica',
    'podcast',
    'cajanegra',
  ]
  const title = [
    'YouTuber',
    'Instagrammer',
    'TikToker',
    'Twit Star',
    'Streamer',
    'Gamer',
    'Freestyler',
    'Música',
    'Podcast',
    'Caja Negra',
  ]
  const subtitle = [
    'Elegí quién te hizo estallar tu lista de sugeridos.',
    'Elegí quién se ganó tus mejores likes.',
    'Elegí quién te hipnotizó durante más horas.',
    'Elegí quién fue la gran tendencia.',
    'Elegí quién te cautivó con sus vivos.',
    'Elegí quién la rompió toda.',
    'Elegí quién te hizo saltar del sillón con las mejores rimas.',
    'Elegí quién encabezó tu lista de artistas más escuchadxs.',
    'Elegí cuál te hizo escucharlo sin descanso.',
    'Elegí qué capítulo te hizo terminar de descubrir a tu artista favoritx.',
  ]
  const [index, setIndex] = useState(0)
  let { userId, updateVoteUser } = useUserCheck()
  let { user } = useUser(userId)

  const goToNext = useCallback(
    async (index, vote) => {
      user.votes[index] = vote
      let empties = user?.votes?.length - user?.votes?.filter(word => word !== '0').length
      updateVoteUser(user, userId, index, vote)
      console.log('Go To Index', index, vote, empties, user?.votes)
      if (index >= 9) {
        forceUpdateHandler(4)
      } else {
        setIndex(index + 1)
      }
    },
    [forceUpdateHandler, updateVoteUser, user, userId]
  )

  // const handleButtonClick = useCallback(async () => {
  //   {
  //     index, slug, title
  //   }
  // }, [createUser, forceUpdateHandler])

  return (
    <div className="px-6 mb-10">
      <ProgressBar index={index} user={user}></ProgressBar>
      <Fade delay={100}>
        <TitleBox slug={slug[index]} subtitle={subtitle[index]}></TitleBox>
      </Fade>
      <Fade delay={200}>
        <SurveyBox
          index={index}
          data={data[slug[index]]}
          slug={slug[index]}
          title={title[index]}
          goToNext={goToNext}
        ></SurveyBox>
      </Fade>
    </div>
  )
}

SurveyScreen.propTypes = {
  forceUpdateHandler: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default SurveyScreen

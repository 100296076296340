import { useEffect } from 'react'
import ReactGA from 'react-ga'

import PhaseOneLayout from '~/components/PhaseOneLayout'
import PhaseTwoLayout from '~/components/PhaseTwoLayout.js'
import Splash from '~/components/Splash.js'

/**
 * Constants
 */

const ISPHASEONE = process.env.REACT_APP_ISPHASEONE === 'TRUE'

/**
 * App
 */

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <>
      <div className="bg-black">
        <main className="relative overflow-x-hidden h-full">
          {ISPHASEONE && <PhaseOneLayout />}
          {!ISPHASEONE && <PhaseTwoLayout />}
        </main>
        <Splash />
      </div>
    </>
  )
}

/**
 * Exports
 */

export default App

const ISPHASEONE = process.env.REACT_APP_ISPHASEONE === 'TRUE'
const LOCAL_STORAGE_KEY = ISPHASEONE ? 'user_id' : 'user_id_phase2'

export const setUserId = userId => {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, userId)
}

export const removeUserId = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_KEY)
}

export const getUserId = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEY)
}

import { useEffect, useRef, useState } from 'react'

/**
 * Header
 */

const Splash = () => {
  const [showLoading, setShowLoading] = useState(true)
  const [initFadeOut, setInitFadeOut] = useState(false)

  const timerToClearSomewhere = useRef(null) // now you can pass timer to another component

  useEffect(() => {
    timerToClearSomewhere.current = setTimeout(() => setShowLoading(false), 1500)
    timerToClearSomewhere.current = setTimeout(() => setInitFadeOut(true), 1000)

    return () => {
      clearTimeout(timerToClearSomewhere.current)
    }
  }, [])

  return (
    showLoading && (
      <div className="bg-black">
        <div className={`splash ${initFadeOut ? 'hide' : ''}`}>
          <div className="splash_logo">
            <img className="mx-auto my-2" src="/images/logo_footer.svg" />
          </div>
        </div>
        <style jsx>{`
          .splash {
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            overflow: hidden;
            background-color: black;
            -webkit-transition: opacity 1s ease-in-out;
            -moz-transition: opacity 1s ease-in-out;
            -ms-transition: opacity 1s ease-in-out;
            -o-transition: opacity 1s ease-in-out;
          }
          .splash_logo {
            position: absolute;
            margin: -60px 0 0 -75px;
            top: 50vh;
            z-index: 5;
            left: 50vw;
            width: 150px;
            text-align: center;
            height: 120px;
            font-size: 26px;
            font-weight: 600;
            color: #ffffff;
            opacity: 1;
            -webkit-transition: opacity 0.3s ease-in-out;
            -moz-transition: opacity 0.3s ease-in-out;
            -ms-transition: opacity 0.3s ease-in-out;
            -o-transition: opacity 0.3s ease-in-out;
          }
          .splash.hide {
            opacity: 0;
          }
          .splash.hide .splash_logo {
            opacity: 0;
          }
        `}</style>
      </div>
    )
  )
}

/**
 * Exports
 */

export default Splash

import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import * as apiClient from '~/lib/api-client'
import App from './App'
import reportWebVitals from './reportWebVitals'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '~/styles/main.css'
import '~/styles/global.css'

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API,
  plugins: [new BugsnagPluginReact()],
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING_ID)
apiClient.init(process.env.REACT_APP_API_BASE_URL)

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import PropTypes from 'prop-types'

function TitleBox({ slug, subtitle }) {
  return (
    <div className="mt-8">
      <img className="" src={`/images/title_${slug}.svg`} />
      <div className="pt-1 h-16 w-11/12">
        <p className="text-lg text-white text-secondary">{subtitle}</p>
      </div>
    </div>
  )
}

TitleBox.propTypes = {
  slug: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}

/**
 * Exports
 */

export default TitleBox

import axios from 'axios'

export const init = baseURL => {
  axios.defaults.baseURL = baseURL

  axios.interceptors.request.use(function(config) {
    config.headers['Access-Control-Allow-Origin'] = '*'

    return config
  })
}

export default axios
